<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{error_message}}</CAlert>
    </div>
    <!-- Filters to search-->
    <section>
      <CCard>
        <CCardHeader>
          <strong>Tipo de Clientes</strong>
          <div class="card-header-actions">
            <b-button @click="submit">Consultar</b-button>
          </div>
        </CCardHeader>
        <CCardBody>
          <b-row>
            <b-col lg="3">
              <b-form-group>
                  <small>Fecha</small>
                  <v-date-picker                   
                    v-model="selectedDate"/>  
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group>
                <small>Tipo</small>
                <v-select
                  v-model="clientActivitySelected"
                  :options="clientActivityOpts"
                  placeholder="Selecciona un tipo">                
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                  <small>Ciudades</small>
                  <v-select
                    v-model="citySelected"
                    :options="cities"
                    placeholder="Selecciona tu ciudad">
                  </v-select>   
              </b-form-group>
            </b-col>
          </b-row>
        </CCardBody>
      </CCard>
    </section>

    <!-- Filters of results-->
      <!-- Fitlers -->
    <section>
      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar por"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Orden inicial"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc', 'last']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtro"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Escribe para buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            label="Filtrar por"
            description="Deja todo sin seleccionar para filtrar por todos los datos"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >              
              <b-form-checkbox value="name">Cliente</b-form-checkbox>              
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Por página"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <strong>
            Total items: {{items.length}}
          </strong>
        </b-col>
      </b-row>
    </section>
    <!-- Table -->
    <section>
      <CCard>
        <CCardHeader>
          <strong>Tabla</strong>
        </CCardHeader>
        <CCardBody>
          <b-row>
            <b-col lg="12">
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                fixed                
                hover
                bordered
                @filtered="onFiltered">                
              </b-table>
            </b-col>
          </b-row>
        </CCardBody>
      </CCard>
    </section>
    
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { DatePicker } from "v-calendar";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
const today = new Date();
export default {
  name: 'clientsType',
  components: {
    Loading,
    "v-date-picker": DatePicker
  },
  data() {
    return {
      loading: true,
      errors: [],
      selectedDate: new Date(),
      clientActivityOpts: [
        {value: 1, label: 'Activo'},
        {value: 2, label: 'No recurrente'},
        {value: 3, label: 'Inactivo'},
      ],
      clientActivitySelected: {value:1, label: 'Activo'},
      cities: [],
      citySelected: {value: 1, label: "Popayán, Cauca, Colombia"},
      // Table data
      items: [],
      fields: [
        { key: "i", label: "#" },
        { key: "id", label: "Id" },
        { key: "name", label: "Nombre",sortable: true },
        { key: "phone", label: "Celular" },
        { key: "email", label: "Email" },
        { key: "petName", label: "Mascota" },
        { key: "paymentId", label: "Id pago" },
        { key: "lastServiceDate", label: "Ult. servicio" },
        { key: "totalPayment", label: "Ult. pago" },
        { key: "typeService", label: "Servicio" },
        { key: "servicesPackage", label: "Cantidad" },
        { key: "paymentDate", label: "Fecha pago" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 20, 40, { value: 120, text: "Muestra muchas" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],      
    }
  },
  validations: {
    citySelected: {
      required,
    },
    clientActivitySelected: {
      required,
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  computed: {  
    sortOptions: {
      get() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key };
          });
      },
      set(newValue) {
        return newValue
      }      
    },
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    
    if (this.userProfile.status.id == 2) {
      this.fetchCities();  
    // this.fetchClientsCreated();
    } else {
      this.errors.push("No tienes acceso a este módulo");
    }
    this.loading = false;
  },
  methods: {
    fetchCities() {
      let getCities = this.$firebase.functions().httpsCallable('getCities')
      getCities().then((response) => {
        if(response.data != null) {
          let cities = response.data.data          
          if(this.userProfile.acceso.id == 1) {
            //owner
            cities.map((city,i) => { 
              if(city.id != 3){
                  this.cities.push({
                  value: city.id,
                  label: city.nombre,
                })
              }           
             
            })
          } else if (this.userProfile.acceso.id == 2) {
            //admin            
            let cityFound = cities.find(city => city.nombre == this.userProfile.ciudad)            
            this.citySelected  = {
              value: cityFound.id,
              label: cityFound.nombre
            } 
            this.cities = [{
              value: cityFound.id,
              label: cityFound.nombre
            } ]

          }
          
        }        
      }).catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
        this.errors = ["No has completado correctamente los filtros"];
      } else {
        this.loading = true
        this.fetchKpis();
      }
    },
    fetchKpis(){
      let payload = {
        date: moment(this.selectedDate).format('yyyy-MM-DD'),
        city: this.citySelected,
        clientActivity: this.clientActivitySelected,
      }
      // console.log(payload)
      let getClientsActivityKpi = this.$firebase.functions().httpsCallable('getClientsActivityKpi');
      getClientsActivityKpi(payload).then((response) => {                
        // console.log('response: ', response);
        let data = response.data.data
        if(data != null){
            this.items = data
          // console.log(data)
          this.totalRows = this.items.length
        }else {
          console.log('Error');
          console.log(response.data.error);
        }
      
      })
      .catch(error => console.log(error))
      .finally(_=>this.loading = false)

    }
  }
}
</script>

<style>

</style>